import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Layer 2",
  viewBox: "0 0 420.95 420.95"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { "data-name": "Layer 1" }, [
      _createElementVNode("path", {
        d: "M359.5 61.45C319.95 21.89 266.27 0 210.47 0S101 21.89 61.45 61.45 0 154.68 0 210.47s21.89 109.47 61.45 149.02c39.55 38.85 93.23 61.45 149.03 61.45s109.47-21.9 149.03-61.45 61.45-93.23 61.45-149.02-21.89-109.48-61.45-149.03Zm-28.96 269.8c-31.78 31.78-75.57 50.15-120.77 50.15S120.78 363.04 89 331.25c-32.49-31.78-50.15-75.57-50.15-120.77S57.21 121.49 89 89.71c31.78-32.49 75.57-50.15 120.77-50.15s88.99 18.36 120.77 50.15c32.49 31.78 50.15 75.57 50.15 120.77s-18.36 88.99-50.15 120.77",
        style: {"fill-rule":"evenodd","stroke-width":"0"}
      }),
      _createElementVNode("path", {
        d: "M210.42 211.89c-10.59 0-19.78 9.18-19.78 19.78v84.75c0 10.59 9.18 19.78 19.78 19.78s19.78-9.18 19.78-19.78v-84.75c0-10.59-9.18-19.78-19.78-19.78M209.87 107.6c-7.77 0-15.54 2.83-21.89 9.18-5.65 5.65-9.18 13.42-9.18 21.89s2.83 15.54 9.18 21.19c6.36 5.65 13.42 9.18 21.89 9.18 8.48 0 15.54-3.53 21.89-9.18s9.18-12.71 9.18-21.19-3.53-16.24-9.18-21.89c-6.36-6.36-14.13-9.18-21.89-9.18",
        style: {"fill-rule":"evenodd","stroke-width":"0"}
      })
    ], -1)
  ])))
}
export default { render: render }